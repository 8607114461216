import React, { useEffect, useState } from "react";
import { Radio, Button,message } from "antd";
import { useDispatch, useSelector } from "react-redux";
const StunTurn = () => {
  const user = useSelector((state) => state.loginReducer.user);
  const [value, setValue] = useState(()=>localStorage.getItem("stun_turn"));

  // useEffect(() => {
  //   let nat_traversal = user?.agents[0]?.nat_traversal;
  //     nat_traversal = nat_traversal.replace(/\s+/g, " ");
      
  //     let pcConfig = JSON.parse(nat_traversal);
  //     console.log("[STUN/TURN]",{ pcConfig });
  //     console.log("[STUN/TURN]:pcConfig.iceServers=",pcConfig.iceServers[0].urls);
  //     let urls=pcConfig.iceServers[0].urls;
  //     console.log("[STUN/TURN]:urls=",urls);
  // },[user])
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    console.log(user);
    setValue(e.target.value);
    localStorage.setItem("stun_turn", e.target.value);
  };

    const handleSaveSTUNTURN = () => {
      localStorage.setItem("stun_turn", value);
      message.success("Save successfully");
    }
  return (
    <>
      <Radio.Group onChange={onChange} value={value} className="colFlex gap10">
        <Radio value={"tcp"}>STUN/TURN, TCP</Radio>
        <Radio value={"udp"}>STUN/TURN, UDP</Radio>
        <Radio value={"none"}>Disable STUN/TURN</Radio>
      </Radio.Group>
      <Button style={{ marginTop: "10px" }} onClick={handleSaveSTUNTURN}>Save</Button>
    </>
  );
};

export default StunTurn;
