import React, { useEffect, useState, useRef, use } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Col, Row, message } from "antd";

import {
  PhoneOutlined,
  CopyOutlined,
  PauseOutlined,
  SwapOutlined,
  CaretRightOutlined,
  AudioMutedOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import { Routes, Route, Link, useLocation, useMatch } from "react-router-dom";
import Draggable from "react-draggable";
import axios from "axios";
import Numpad from "./Numpad";
import moment from "moment";
import useAxios from "./../CustomHooks/useAxios";
import TiketNoteIn from "./TicketNoteIn";

import "./Call.css";
import { addCallInfo_Ticket } from "./../redux/jsSIPSlice";

export default function CallIn({
  id,
  mysession,
  mysessionInfo,
  setMySessionsIdTicket,
  stopRingTone,
  // audioUrl, setAudioUrl
  addDebugLog,
  addDebugLogMessage,
  resetDebugLog,
  ringToneTime,playRemoteAudio,remoteAudioTime
}) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  // console.log("[CallIn]:",{id,mysession,mysessionInfo});
  const uniqueid = mysessionInfo?.uniqueid[0].raw;
  // console.log("[CallIn]:uniqueid=",uniqueid);
  const callInAudioRef = useRef(null);
  const [callTime, setCallTime] = useState(0);
  const [audioUrl, setAudioUrl] = useState(null); // URL của file âm thanh được lưu
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]); // Lưu các blob âm thanh

  const { axiosi } = useAxios();
  const dispatch = useDispatch();

  const [displayKeypad, setDisplayKeypad] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [priority_level, setPriority_level] = useState("");
  const [tel, setTel] = useState("");
  const audioPlayers = useSelector((state) => state.jsSIPReducer.audioPlayers);
  // console.log("[CallIn]:", {uniqueid, id, mysession, mysessionInfo });
  const [customerId, setCustomerId] = useState("");
  const [isOpenTicket, setIsOpenTicket] = useState(false);
  const [callStatus, setCallStatus] = useState("Ringing...");
  const [counttime, setCounttime] = useState(0);
  const [loop, setLoop] = useState();
  const doOpenTiket = () => {
    // setMySessionsIdTicket((prev)=>([...prev,id]));
    setIsOpenTicket(true);
    dispatch(
      addCallInfo_Ticket({
        id,
        call_id: mysessionInfo.call_id,
        tel: mysessionInfo?.tel,
        mysessionInfo,
        customerInfo,
      })
    );
    setMySessionsIdTicket((prev) => [...prev, id]);
  };
  const uploadAudio = async (audioBlob) => {
    try {
      const formData = new FormData();
      formData.append("uniqueid", `${uniqueid}`);
      formData.append("file", audioBlob, `${uniqueid}.webm`);
      // formData.append(uniqueid, audioBlob, `${uniqueid}.webm`);

      const response = await axiosi.post(
        `${process.env.REACT_APP_API_URL}/debuglog/uploadAudio`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Upload successful:", response.data);
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };
  const handleStopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
    }
  };
  
  useEffect(() => {
    const prevPosition = JSON.parse(localStorage.getItem("CallInWindowPosition"));
    if (prevPosition) {
      setPosition(prevPosition);
    }
  },[]);
  useEffect(() => {
    addDebugLogMessage(`[CallIn]: Hien thi CallIn Window`);
  }, []);
  useEffect(() => {
    const audio = callInAudioRef.current;
    if (!audio) {
      return;
    }
    const handleTimeUpdate=() => {
      setCallTime(audio.currentTime);
      addDebugLogMessage(`[callInAudio]: timeupdate: ${audio.currentTime}`);
    };
    

    // Định nghĩa callback
    const handleCanPlay = () => addDebugLogMessage("[callInAudio]: canplay");
    const handleEnded = () => addDebugLogMessage("[callInAudio]: ended");
    
    const handleError = () => addDebugLogMessage("[callInAudio]: error");
    const handleStalled = () => addDebugLogMessage("[callInAudio]: stalled");
    const handleSuspend = () => addDebugLogMessage("[callInAudio]: suspend");
    const handleAbort = () => addDebugLogMessage("[callInAudio]: abort");

    audio.addEventListener("canplay", handleCanPlay);
    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("error", handleError);
    audio.addEventListener("stalled", handleStalled);
    audio.addEventListener("suspend", handleSuspend);
    audio.addEventListener("abort", handleAbort);
    return () => {
      if (audio) {
        audio.removeEventListener("canplay", handleCanPlay);
        audio.removeEventListener("ended", handleEnded);
        audio.removeEventListener("timeupdate", handleTimeUpdate);
        audio.removeEventListener("error", handleError);
        audio.removeEventListener("stalled", handleStalled);
        audio.removeEventListener("suspend", handleSuspend);
        audio.removeEventListener("abort", handleAbort);
        
      }
    };
  }, []);
  useEffect(() => {
    const getCustomerInfoByTel = async (tel) => {
      try {
        const kq = await axiosi.get("comcontacts/tel", { params: { tel } });
        const kqdata = await kq.data;
        console.log("[getCustomerInfoByTel]:kqdata=", kqdata);
        console.log("[getCustomerInfoByTel]:kqdata.rows=", kqdata.rows);

        if (kqdata.rows.length > 0) {
          setCustomerInfo(kqdata.rows);
          setCustomerId(kqdata.rows[0]["id"]);
          setPriority_level(` (Priority: ${kqdata.rows[0].priority_level})`);
        } else {
          setCustomerInfo(null);
          setCustomerId("");
          setPriority_level("");
        }
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log("error.response.data=", error.response.data);
          console.log("error.response.status=", error.response.status);
          console.log("error.response.headers=", error.response.headers);
          // message.error(`Error: ${error.response.data.code}`);
          setCustomerInfo(null);
          setPriority_level("");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          setCustomerInfo(null);
          setPriority_level("");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          setCustomerInfo(null);
          setPriority_level("");
        }
        console.log(error.config);
        setCustomerInfo(null);
        setPriority_level("");
      }
    };
    if (mysessionInfo?.tel === "asterisk") {
      const aa = mysessionInfo?.display_name.split("amiOK-");
      if (aa.length > 1) {
        setTel(aa[1]);
        getCustomerInfoByTel(aa[1]);
        // handleHangup();
      } else {
        setTel(mysessionInfo?.tel);
        getCustomerInfoByTel(mysessionInfo?.tel);
      }
    } else {
      setTel(mysessionInfo?.tel);
      getCustomerInfoByTel(mysessionInfo?.tel);
    }
  }, [mysessionInfo?.tel, mysessionInfo?.display_name]);
  const handleHangup = () => {
    addDebugLog({tel:mysessionInfo?.tel, message: "handleHangup" });
    mysession.terminate();
    console.log("jssipCall_Hangup");
    // audioPlayers.Ring.pause();
    stopRingTone();
    
  };

  function rdDuration(d) {
    if (d > 0) {
      const idtime = new Date(d * 1000).toISOString().substr(11, 8);
      return "Connected: " + idtime;
    } else {
      return "Call status: " + callStatus;
    }
  }
  useEffect(() => {
    mysession.on("accepted", (data) => {
      // console.log(
      //   "[Callin.js - mysessions.current[e.session.id].on = confirmed]:data=",
      //   data
      // );
      setCallStatus("Connected");

      // setLoop(
      //   setInterval(() => {
      //     console.log("loading");
      //     setCounttime((pre) => pre + 1);
      //     // count();
      //   }, 1000)
      // );
    });

    // return function cleanup() {
    //   console.log("cleaning up");
    //   clearInterval(loop);
    // };
  }, [mysession, loop]);

  const handleAnswer = () => {
    console.log("handleAnswer");
    // Register callbacks to desired call events
    var eventHandlers = {
      progress: function (e) {
        console.log("call is in progress", e);
       
      },
      failed: function (e) {
        // audioPlayers.Ring.pause();
        // handleStopRecording();
        //stopRingTone();
        console.log("[eventHandlers]:call failed with cause: ", e);
        
      },
      ended: function (e) {
        // audioPlayers.Ring.pause();
        // handleStopRecording();
        // stopRingTone();
        console.log("[eventHandlers]:call ended with cause: ", e);
        
        // if (e.originator === "remote") {
        //   console.log("[eventHandlers-failed_]:remote-> playEND");
        //   audioPlayers.End.play();
        //   setTimeout(() => {
        //     audioPlayers.End.pause();
        //   }, 1500);
        // }
      },
      confirmed: function (e) {
        console.log("call confirmed", e);
        
      },
      peerconnection: function (e) {
        console.log("peerconnection", e);
        
      },
    };

    var options = {
      // eventHandlers: eventHandlers,
      mediaConstraints: { audio: true, video: false },
    };

    
    stopRingTone();
    mysession.answer(options);
    setDisplayKeypad(true);
    
    // mysession.connection.addEventListener("track", function (e1) {
    //   let stream = e1.streams[0];
    //   playRemoteAudio(stream,mysessionInfo?.tel);
      
    // });
    
    addDebugLog({tel:mysessionInfo?.tel, message: "handleAnswer" });
  };
  const displayCustomer = () => {
    // console.log("[displayCustomer]:customerInfo=",customerInfo);
    let or = "";
    let i = 0;
    return customerInfo.map((item) => {
      i++;
      if (i > 1) {
        or = <h3>Or </h3>;
      }
      const a = (
        <div key={item.id}>
          {or}
          <ul key={item.id}>
            <li>Name : {item.name}</li>
            <li>Company : {item.ccomname}</li>
            <li>Gender : {item.gender}</li>
            <li>
              Birthday : {item.dob && moment(item.dob).format("YYYY-MM-DD")}
            </li>
            <li>
              Tel : {item.tel}{" "}
              <span
                className="CopyColor"
                title="Click to Copy !"
                onClick={() => handleCopy(item.tel)}
              >
                <CopyOutlined />
              </span>
            </li>
            <li>Note : {item.note}</li>
          </ul>
        </div>
      );
      switch (i) {
        case 1:
          return a;
        //  case 2: return a;
        default:
          return (
            <div key={item.id}>
              <h3>Or </h3>
              <ul key={item.id}>
                <li>Name : {item.name}</li>
                <li>Company : {item.ccomname}</li>
                <li>...</li>
              </ul>
            </div>
          );
      }
    });
  };
  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    message.info({
      content: "copied !",
      className: "custom-class",
      style: {
        position: "absolute",
        top: "-15px",
        right: "1px",
      },
    });
  };
  const handleOnDrag = (e, ui) => {
    let { x, y } = position;
    x= x + ui.deltaX;
    y= y + ui.deltaY;
    setPosition({ x, y });
    localStorage.setItem("CallInWindowPosition", JSON.stringify({ x, y }));
    // setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });    
    // console.log("[handleOnDrag]:", { x, y });

  };
  return (
    <Draggable
      onDrag={handleOnDrag}
      // axis="x"
      handle=".moveable .ant-card-head"
      // defaultPosition={{ x: 0, y: 0 }}
      position={position}
      grid={[25, 25]}
      scale={1}
      // onStart={this.handleStart}
      // onDrag={this.handleDrag}
      // onStop={this.handleStop}
    >
      <div className="site-card-border-less-wrapper popupCallBox moveable">
        <audio ref={callInAudioRef}  />
        <Card
          title={`Call from : ${tel} ${priority_level}`  }
          bordered={false}
          style={{ width: 300 }}
        >
          {displayKeypad && (
            <Numpad
              callTime={remoteAudioTime}
              // callStatus={rdDuration(counttime)}
              callStatus={rdDuration(remoteAudioTime)}
              mysession={mysession}
              mysessionInfo={mysessionInfo}
              handleHangup={handleHangup}
              addDebugLog={addDebugLog}
              addDebugLogMessage={addDebugLogMessage}
              resetDebugLog={resetDebugLog}
            />
          )}
          {!customerInfo && (
            <h2>
              {mysessionInfo?.tel}-NewCustomer{" "}
              <span
                className="CopyColor"
                title="Click to Copy !"
                onClick={() => handleCopy(mysessionInfo?.tel)}
              >
                <CopyOutlined />
              </span>
            </h2>
          )}
          <div style={{ overflow: "auto", "max-height": "300px" }}>
            {customerInfo?.length === 1 && (
              <ul>
                <li>Name : {customerInfo[0].name}</li>
                <li>Company : {customerInfo[0].ccomname}</li>
                <li>Gender : {customerInfo[0].gender}</li>
                <li>
                  Birthday :{" "}
                  {customerInfo[0].dob &&
                    moment(customerInfo[0].dob).format("YYYY-MM-DD")}
                </li>
                <li>
                  Tel : {customerInfo[0].tel}
                  <span
                    className="CopyColor"
                    title="Click to Copy !"
                    onClick={() => handleCopy(customerInfo[0].tel)}
                  >
                    <CopyOutlined />
                  </span>
                </li>
                <li>Note : {customerInfo[0].note}</li>
              </ul>
            )}
            {customerInfo?.length > 1 && displayCustomer()}
          </div>

          {!displayKeypad && (
            <div className="colFlex " style={{gap: "10px"}}>
              <div className="AnswerGroup">
              <Button
                onClick={handleHangup}
                style={{ marginRight: "10px" }}
                type="primary"
                danger
                ghost
              >
                {" "}
                Reject <PhoneOutlined />
              </Button>
              <Button onClick={handleAnswer} type="primary" ghost>
                Answer
                <PhoneOutlined />
              </Button>
              </div>
              <div className="duration">Ringing... : {ringToneTime}</div>
            </div>
          )}
        </Card>
        {!isOpenTicket && (
          <Button type="link" onClick={doOpenTiket}>
            Open a Ticket
          </Button>
        )}
        {/* <Link to={"/tickets?tel="+tel+"&cusid="+customerId}>
                                          {"tickets"}
                                        </Link>     */}
      </div>
    </Draggable>
  );
}
