import React,{useState} from "react";
import { Col, Row, Table, Tag, Space, Button, Typography, Drawer } from "antd";
export default function ShowAgentSummary({ data,pickupSecond }) {
  // const [pickupSecond, setPickupSecond] = useState(process.env.REACT_APP_PICKUP_SECOND);
  const formatTime = (second) => {
    // return("second");

    const b = 86400;
    // second += b*22;
    if (second >= b) {
      let giay = second % b;
      let ngay = (second - giay) / b;
      //new Date(3600*24 * 1000).toISOString().substr(11, 8)
      return (
        `${ngay}d.` + new Date(second * 1000).toISOString().substring(11, 19)
      );
      //${moment(giay).format("HH:mm:ss")}`;
      //+ new Date(giay * 1000).toISOString().substr(11, 8);
      //${moment(giay).format(dateFormat)}`;
    } else {
      return new Date(second * 1000).toISOString().substring(11, 19); //
      // return `${moment(12).format("HH:mm:ss")}`;
    }
    //return `${moment(second).format(dateFormat)}`;
    //new Date(giay * 1000).toISOString().substr(11, 8);//
  };
  function rdRatio(ts,ms){
    if (ms==0){
      return "";
    }
    return Math.round(ts*10000/ms)/100
  }
  return (
    <>
      <Typography.Title level={3} className="mt25">
        {" "}
        Agents's Summary Call
      </Typography.Title>
      <table className="table1">
        <thead>
          <tr>
            <th rowspan="2" width="220px">
              Agent
            </th>
            <th colspan="9">Inbound</th>
            <th colspan="3">Outbound</th>
          </tr>
          <tr>
            <th width="120px">Not connected</th>
            <th width="120px">Missed</th>
            <th width="120px">Missed Cancel</th>
            <th width="80px">Connected</th>
            {/* <th width="80px">Pickup</th> */}
            <th width="80px">AvgPickup</th>
            <th width="80px">Pickup [≤{pickupSecond}s]</th>
            {/* <th width="80px">Talktime</th> */}
            <th width="80px">AvgTalktime</th>
            <th width="80px">Missed (%)</th>
            <th width="80px">Pickup(%) [≤{pickupSecond}s] </th>

            <th width="120px">Not connected</th>
            <th width="80px">Connected</th>
            <th width="80px">AvgTalktime</th>
            {/* <th width="80px">AVG (Talktime) </th>                      */}
          </tr>
          {/* <tr>            
            <th width="520px">Agent</th>
            
            <th >inNotConnected</th>
            <th width="120px">inConnected</th>
            <th width="120px">inConnectedTalkTime</th>

            <th >outNotConnected</th>
            <th width="120px">outConnected</th>
            <th width="120px">outConnectedTalkTime</th>
        </tr> */}
        </thead>
        <tbody>
          
          
          {data.agentCallLogsSum.map(
            (item) =>
              item.ext != "" && (
                <tr key={item.ext + item.agentname}>
                  <td>
                    [{item.ext} - {item.agentname}] 
                  </td>
                  <td> {item.inNotConnected} </td>
                  <td> {item.inMissed} </td>
                  <td> {item.inMissedCancel} </td>
                  <td> {item.inConnected} </td>
                  {/* <td>
                    {" "}
                    {item.inConnectedPickup != 0
                      ? formatTime(item.inConnectedPickup)
                      : ""}{" "}
                  </td> */}
                  <td>
                    {" "}
                    {item.inConnected != 0
                      ? formatTime(item.inConnectedPickup / item.inConnected)
                      : ""}{" "}
                  </td>
                  <td> {item.pickup_less_eq7s} </td>
                  {/* <td>
                    {" "}
                    {item.inConnectedTalkTime != 0
                      ? formatTime(item.inConnectedTalkTime)
                      : ""}{" "}
                  </td> */}
                  <td>
                    {" "}
                    {item.inConnected != 0
                      ? formatTime(item.inConnectedTalkTime / item.inConnected)
                      : ""}{" "}
                  </td>
                  <td>{rdRatio(item.inMissed,(Number(item.inConnected)+Number(item.inNotConnected)))}</td>
                  <td>{rdRatio(item.pickup_less_eq7s,(Number(item.inConnected)))} </td>
                  <td> {item.outNotConnected} </td>
                  <td> {item.outConnected} </td>
                  <td>
                    {/* {" "}
                    {formatTime(item.outConnectedTalkTime)}{" "} */}
                    {item.outConnected != 0
                      ? formatTime(
                          item.outConnectedTalkTime / item.outConnected
                        )
                      : ""}{" "}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </>
  );
}
