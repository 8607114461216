import React, { useEffect, useState ,useRef, use} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Row, Col, message } from "antd";
import {
  PhoneTwoTone,
  PhoneOutlined,
  CopyOutlined,
  AudioMutedOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import { Routes, Route, Link, useLocation, useMatch } from "react-router-dom";
import moment from "moment";

import Draggable from "react-draggable";
import useAxios from "./../CustomHooks/useAxios";
import { addCallInfo_Ticket } from "./../redux/jsSIPSlice";

import Numpad from "./Numpad";
import TiketNote from "./TicketNote";
import "./Call.css";

export default function CallOut({
  id,
  mysession,
  mysessionInfo,
  setMySessionsIdTicket,
  addDebugLog,
  addDebugLogMessage,
  resetDebugLog,
  remoteAudioTime
  
}) {
  const uniqueid="uniqueid";//mysessionInfo?.uniqueid[0].raw;
  // console.log("[CallOut]:",{id,mysession,mysessionInfo});
  const { axiosi } = useAxios();
  const dispatch = useDispatch();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [customerInfo, setCustomerInfo] = useState(null);
  const [customerInfoCount, setCustomerInfoCount] = useState(0);
  const [priority_level, setPriority_level] = useState("");
  const audioPlayers = useSelector((state) => state.jsSIPReducer.audioPlayers);
  const [customerId, setCustomerId] = useState("");
  const [isOpenTicket, setIsOpenTicket] = useState(false);
  const [callStatus, setCallStatus] = useState("Calling...");
  const [counttime, setCounttime] = useState(0);
  const [loop, setLoop] = useState();
  const [mute, setmute] = useState(false);
  const [tel, setTel] = useState("");
  const [callOutType, setCallOutType] = useState("out");
  const [telInfo, setTelInfo] = useState([]);
  const audioRef = useRef(null);
  const [audioTime, setAudioTime] = useState(0);

  const [audioUrl, setAudioUrl] = useState(null); // URL của file âm thanh được lưu
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]); // Lưu các blob âm thanh

  const handleMute = () => {
    if (!mute) {
      mysession.mute();
      setmute((pre) => !pre);
    } else {
      mysession.unmute();
      setmute((pre) => !pre);
    }
  };

  const doOpenTiket = () => {
    setIsOpenTicket(true);
    // console.log("==========DOING setMySessionsIdTicket, id=",id);
    // console.log("==========DOING setMySessionsIdTicket, call_id=",mysessionInfo.call_id);

    console.log("[doOpenTiket]:mysessionInfo?.tel=", mysessionInfo?.tel);
    dispatch(
      addCallInfo_Ticket({
        id,
        callOutType,
        telInfo,
        call_id: mysessionInfo.call_id,
        tel: mysessionInfo?.tel,
        mysessionInfo,
        customerInfo,
      })
    );
    setMySessionsIdTicket((prev) => [...prev, id]);
  };
  const handleHangup = () => {
    mysession.terminate();
    console.log("jssipCall_Hangup");
    audioPlayers.Ring.pause();
  };
  function doSendDTMF(digit) {
    // console.log("[doSendDTMF]:digit=",digit);
    var options = {
      transportType: "RFC2833",
    };
    mysession.sendDTMF(digit, options);
  }
  const handleSendDTMF = () => {
    doSendDTMF(1);
  };
  const count = () => {
    console.log("[count]:counttime=", counttime);
    setCounttime((pre) => pre++);
  };
  function rdDuration(d) {
    if (d > 0) {
      const idtime = new Date(d * 1000).toISOString().substr(11, 8);
      return "Connected: " + idtime;
    } else {
      return "Call status: " + callStatus;
    }
  }
  // function rdDuration(d) {
  //   if (d > 0) {
  //     const idtime = new Date(d * 1000).toISOString().substr(11, 8);
  //     return `Call status: ${callStatus} ` + idtime;
  //   } else {
  //     return "Call status: " + callStatus;
  //   }
  // }
  // useEffect(() => {
  //   mysession.connection.addEventListener(
  //     "addstream",
  //     function (e1) {
  //       const remoteAudio = audioRef.current;        
  //       remoteAudio.srcObject = e1.stream;
  //       remoteAudio.play();
  //     }
  //   );
  //   return function cleanup() {
  //     mysession.connection.removeEventListener(
  //       "addstream", 
  //     )
  //   };
  // },[mysession]);
  const uploadAudio = async (audioBlob) => {
    try {
      const formData = new FormData();
      const tel=mysessionInfo?.tel;
      formData.append("uniqueid", tel);
      // formData.append("tel", mysessionInfo?.tel);
      formData.append("file", audioBlob, `${uniqueid}.webm`);
      // formData.append(uniqueid, audioBlob, `${uniqueid}.webm`);
      

      const response = await axiosi.post(`${process.env.REACT_APP_API_URL}/debuglog/uploadAudio`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Upload successful:", response.data);
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };
  const handleStopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
    }
  };
  useEffect(() => {
    const prevPosition = JSON.parse(localStorage.getItem("CallOutWindowPosition"));
    if (prevPosition) {
    setPosition(prevPosition);
    };
  },[]);
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) {
      return;
    }
    const handleTimeUpdate=() => {
      setAudioTime(audio.currentTime);
      addDebugLogMessage(`[calloutAudio]: timeupdate: ${audio.currentTime}`);
    };
    // audio.addEventListener("timeupdate", handleTimeUpdate);
    // return () => {
    //   audio.removeEventListener("timeupdate", () => {
    //     setAudioTime(audio.currentTime);
    //   });
    // };
    // Định nghĩa callback
    const handleCanPlay = () => addDebugLogMessage("[calloutAudio]: canplay");
    const handleEnded = () => addDebugLogMessage("[calloutAudio]: ended");
    // const handleTimeUpdate = () => {
    //   setRingToneTime(audio.currentTime);
    //   addDebugLogMessage(`[ringTone]: timeupdate: ${audio.currentTime}`);
    // };
    const handleError = () => addDebugLogMessage("[calloutAudio]: error");
    const handleStalled = () => addDebugLogMessage("[calloutAudio]: stalled");
    const handleSuspend = () => addDebugLogMessage("[calloutAudio]: suspend");
    const handleAbort = () => addDebugLogMessage("[calloutAudio]: abort");

    audio.addEventListener("canplay", handleCanPlay);
    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("error", handleError);
    audio.addEventListener("stalled", handleStalled);
    audio.addEventListener("suspend", handleSuspend);
    audio.addEventListener("abort", handleAbort);
    return () => {
      if (audio) {
        audio.removeEventListener("canplay", handleCanPlay);
        audio.removeEventListener("ended", handleEnded);
        audio.removeEventListener("timeupdate", handleTimeUpdate);
        audio.removeEventListener("error", handleError);
        audio.removeEventListener("stalled", handleStalled);
        audio.removeEventListener("suspend", handleSuspend);
        audio.removeEventListener("abort", handleAbort);
        // audio.removeEventListener("timeupdate", () => {
        //   setAudioTime(audio.currentTime);
        // });
      }
    };
  }, []);
  useEffect(() => {
    function handleAddStream(e1) 
      {
        
        const remoteAudio = audioRef.current;       
        if (remoteAudio){ 
        remoteAudio.srcObject = e1.streams[0];
        remoteAudio.play();
        if (!mediaRecorderRef.current) {
          try {
            const mediaRecorder = new MediaRecorder(e1.streams[0]);
            mediaRecorderRef.current = mediaRecorder;
  
            mediaRecorder.ondataavailable = (event) => {
              if (event.data.size > 0) {
                audioChunksRef.current.push(event.data);
              }
            };
  
            mediaRecorder.onstop = () => {
              const audioBlob = new Blob(audioChunksRef.current, {
                type: "audio/webm",
              });
              const audioUrl = URL.createObjectURL(audioBlob);
              setAudioUrl(audioUrl); // Lưu URL để có thể tải xuống
              uploadAudio(audioBlob);
              audioChunksRef.current = []; // Reset chunks
            };
  
            mediaRecorder.start();
          } catch (error) {
            console.error("Failed to start MediaRecorder:", error);
          }
        }
        }else {
          console.log("remoteAudio is null");
        }
      }
    mysession.connection.addEventListener(
      "track1",
      handleAddStream
    );
    return function cleanup() {
      mysession.connection.removeEventListener(
        "track1", handleAddStream
      )
    };
  },[mysession]);
  useEffect(() => {
    const handleConfirmed=(data) => {
      console.log(
        "[Callout.js - mysessions.current[e.session.id].on = confirmed]:data=",
        data
      );
      setCallStatus("Connected");

      setLoop(
        setInterval(() => {
          // console.log("loading");
          setCounttime((pre) => pre + 1);
          // count();
        }, 1000)
      );
    }
    mysession.on("confirmed",handleConfirmed );

    return function cleanup() {
      console.log("cleaning up confirmed");
      mysession.off("confirmed",handleConfirmed );
      clearInterval(loop);
    };
  }, [mysession, loop]);

  useEffect(() => {
    mysession.on("progress", (data) => {
      setCallStatus("Connecting...");
    });
  }, [mysession]);
  useEffect(() => {
    mysession.on("connecting", (data) => {
      setCallStatus("connecting...");
    });
  }, [mysession]);
  useEffect(() => {
    mysession.on("sending", (data) => {
      setCallStatus("sending...");
    });
  }, [mysession]);
  useEffect(() => {
    mysession.on("progress", (data) => {
      setCallStatus("progress...");
    });
  }, [mysession]);
  useEffect(() => {
    mysession.on("update", (data) => {
      setCallStatus("update...");
    });
  }, [mysession]);

  useEffect(() => {
    let otel = mysessionInfo?.tel;
    console.log("......................CALLOUT ", { otel });
    if (!otel) return;
    let otel2 = otel.slice(0, 2);
    let tel = otel;
    if (otel2 == "ob") {
      let otel_arr = otel.split("$");
      tel = otel_arr[1];
      setCallOutType("ob");
      setTelInfo([...otel_arr]);
    }
    setTel(tel);
  }, [mysessionInfo?.tel]);
  useEffect(() => {
    const getCustomerInfoByTel = async (tel) => {
      try {
        let otel = tel;
        let otel2 = otel.slice(0, 2);
        // let tel = otel;
        let id = "";
        if (otel2 == "ob") {
          let otel_arr = otel.split("$");
          tel = otel_arr[1];
          id = otel_arr[2];
        }
        // const kq = await axiosi.get("comcontacts/tel", { params: { tel } });
        const kq = await axiosi.get("outbounddata/byId", {
          params: { tel, id },
        });
        const kqdata = await kq.data;
        // console.log("[getCustomerInfoByTel]:kqdata=",kqdata);
        // console.log("[getCustomerInfoByTel]:kqdata.rows=",kqdata.rows);

        if (kqdata.rows.length > 0) {
          setCustomerInfo(kqdata.rows);

          setCustomerId(kqdata.rows[0]["id"]);
          setPriority_level(` (Priority: ${kqdata.rows[0].priority_level})`);
          setCustomerInfoCount(kqdata.rows.length);
        } else {
          setCustomerInfo(null);
          setPriority_level("");
          setCustomerId("");
        }
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log("error.response.data=", error.response.data);
          console.log("error.response.status=", error.response.status);
          console.log("error.response.headers=", error.response.headers);
          // message.error(`Error: ${error.response.data.code}`);
          setCustomerInfo(null);
          setPriority_level("");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          setCustomerInfo(null);
          setPriority_level("");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          setCustomerInfo(null);
          setPriority_level("");
        }
        console.log(error.config);
        setCustomerInfo(null);
        setPriority_level("");
      }
    };
    getCustomerInfoByTel(mysessionInfo?.tel);
  }, [mysessionInfo?.tel]);

  const displayCustomer = () => {
    // console.log("[displayCustomer]:customerInfo=",customerInfo);
    let or = "";
    let i = 0;
    return customerInfo.map((item) => {
      i++;
      if (i > 1) {
        or = <h3>Or </h3>;
      }
      const a = (
        <div key={item.id}>
          {or}
          <ul key={item.id}>
            <li>Name : {item.name}</li>
            <li>Company : {item.ccomname}</li>
            <li>Gender : {item.gender}</li>
            <li>
              Birthday : {item.dob && moment(item.dob).format("YYYY-MM-DD")}
            </li>
            <li>
              Tel : {item.tel}{" "}
              <span
                className="CopyColor"
                title="Click to Copy !"
                onClick={() => handleCopy(item.tel)}
              >
                <CopyOutlined />
              </span>
            </li>
            <li>Note : {item.note}</li>
          </ul>
        </div>
      );
      switch (i) {
        case 1:
          return a;
        //  case 2: return a;
        default:
          return (
            <div key={item.id}>
              <h3>Or </h3>
              <ul key={item.id}>
                <li>Name : {item.name}</li>
                <li>Company : {item.ccomname}</li>
                <li>...</li>
              </ul>
            </div>
          );
      }
    });
  };
  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    message.info({
      content: "copied !",
      className: "custom-class",
      style: {
        position: "absolute",
        top: "-15px",
        right: "1px",
      },
    });
  };
  const displaytel = (otel) => {
    let otel2 = otel.slice(0, 2);
    let tel = otel;
    if (otel2 == "ob") {
      let otel_arr = otel.split("$");
      tel = otel_arr[1];
    }
    return tel;
  };
  if ((mysession?.isCallTransfer==true)) {
    
    return <></>
  }
  const handleOnDrag = (e, ui) => {
    let { x, y } = position;
    x= x + ui.deltaX;
    y= y + ui.deltaY;
    setPosition({ x, y });
    localStorage.setItem("CallOutWindowPosition", JSON.stringify({ x, y }));
    // setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });    
    // console.log("[handleOnDrag]:", { x, y });

  };
  return (
    <>
      <Draggable
      onDrag={handleOnDrag}
        // axis="x"
        //style="transform: translate(-400px, -50px);"
        handle=".moveable .ant-card-head"
        // defaultPosition={{ x: 0, y: 0 }}
        // position={null}
        position={position}
        grid={[25, 25]}
        scale={1}
        // onStart={this.handleStart}
        // onDrag={this.handleDrag}
        // onStop={this.handleStop}
      >
        <div className="site-card-border-less-wrapper popupCallBox moveable">
          <audio ref={audioRef} />
          <Card
            title={`Call to : ${tel} ${priority_level} `}
            bordered={false}
            style={{ width: 300 }}
          >
            {/* <h3>{`Call to : ${mysessionInfo?.tel} ${priority_level} ` }</h3> */}
            {/* <div className="duration">{rdDuration(counttime)}</div> */}

            <Numpad
              callStatus={rdDuration(counttime)}
              // callStatus={rdDuration(remoteAudioTime)}
              callTime={remoteAudioTime}
              mysession={mysession}
              mysessionInfo={mysessionInfo}
              handleHangup={handleHangup}
              addDebugLog={addDebugLog}
              addDebugLogMessage={addDebugLogMessage}
              resetDebugLog={resetDebugLog}
            />
            {customerInfoCount === 0 && (
              <h2>
                {tel}-(New){" "}
                <span
                  className="CopyColor"
                  title="Click to Copy !"
                  onClick={() => handleCopy(tel)}
                >
                  <CopyOutlined />
                </span>
              </h2>
            )}
            <div style={{"overflow":"auto","max-height":"300px"}}>
            {customerInfoCount === 1 && (
              <ul>
                
                <li>Name : {customerInfo[0].name}</li>
                <li>Company : {customerInfo[0].ccomname}</li>
                <li>Gender : {customerInfo[0].gender}</li>
                <li>
                  Birthday :{" "}
                  {customerInfo[0].dob &&
                    moment(customerInfo[0].dob).format("YYYY-MM-DD")}
                </li>
                <li>
                  Tel : {customerInfo[0].tel}
                  <span
                    className="CopyColor"
                    title="Click to Copy !"
                    onClick={() => handleCopy(customerInfo[0].tel)}
                  >
                    <CopyOutlined />
                  </span>
                </li>
                <li>Note : {customerInfo[0].note}</li>
              </ul>
            )}
            {customerInfoCount > 1 && displayCustomer()}

            
            </div>
            
          </Card>

          

          {!isOpenTicket && (
            <Button type="link" onClick={doOpenTiket}>
              Open a Ticket
            </Button>
          )}
        </div>
      </Draggable>

      
    </>
  );
}
