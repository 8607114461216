import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, message, Divider } from "antd";
import {
  SaveOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import { setRingSpeaker, setTalkSpeaker } from "./../redux/jsSIPSlice";
const AudioDevices = ({
  setVisible,
  setRingSpeaker,
  playRingTone,
  stopRingTone,
  playRemoteAudioTest,
  stopRemoteAudio,
  ringToneDevice,
  remoteAudioDevice
}) => {
  const [speackerDevices, setSpeakerDevices] = useState([]);
  const ringSpeaker = useSelector((state) => state.jsSIPReducer.ringSpeaker);
  const talkSpeaker = useSelector((state) => state.jsSIPReducer.talkSpeaker);
  const ringSpeakerIdRef = useRef("");
  const talkSpeakerIdRef = useRef("");

  const dispatch = useDispatch();

  //   useEffect(() => {
  //     dispatch(setRingSpeaker(ringSpeakerIdRef.current));
  //     dispatch(setTalkSpeaker(talkSpeakerIdRef.current));
  //   }, [ringSpeakerIdRef.current, talkSpeakerIdRef.current]);

  useEffect(() => {
    async function requestMicrophonePermission() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        // console.log("Microphone permission granted");
        //alert("Microphone is allowed!");

        navigator.mediaDevices.enumerateDevices().then((devices) => {
          const audioOutputs = devices.filter(
            (device) => device.kind === "audiooutput"
          );
          setSpeakerDevices(audioOutputs);
          localStorage.setItem("audioOutputs", JSON.stringify(audioOutputs));

          console.log("Danh sách thiết bị đầu ra âm thanh:", audioOutputs);
          const selectedRingSpeaker = localStorage.getItem("ringSpeaker");
          if (selectedRingSpeaker) {
            ringSpeakerIdRef.current.value = selectedRingSpeaker;
          }
          const selectedTalkSpeaker = localStorage.getItem("talkSpeaker");
          if (selectedTalkSpeaker) {
            talkSpeakerIdRef.current.value = selectedTalkSpeaker;
          }
        });
      } catch (error) {
        console.error("Microphone permission denied", error);
        alert("Microphone permission denied");
      }
    }
    requestMicrophonePermission();
  }, []);
  const handlerSetSpeaker = (e) => {
    console.log("[handlerSetSpeaker]: speackerDevices=",speackerDevices)
    console.log("ringSpeakerIdRef.current=", ringSpeakerIdRef.current.value);
    console.log("talkSpeakerIdRef.current=", talkSpeakerIdRef.current.value);
    localStorage.setItem("ringSpeaker", ringSpeakerIdRef.current.value);
    localStorage.setItem("talkSpeaker", talkSpeakerIdRef.current.value);
    // ringToneAudio.setSinkId(ringSpeakerIdRef.current.value);
    setRingSpeaker();
    // setVisible(false);
    message.success("Save speakers successfully");
  };
  return (
    <>
      <div className="colFlex gap10">
        <div className="colFlex gap10">
          <Divider style={{ borderColor: "#7cb305" }}>Select Speakers</Divider>
          <div className="colFlex gap10 selectSpeaker">
            <div className="colFlex ">
              <span className="titleBold">Select a speaker for RingTone :</span>
              <select ref={ringSpeakerIdRef}>
                {speackerDevices.map((device) => {
                  return (
                    <option key={device.deviceId} value={device.deviceId}>
                      {device.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="colFlex ">
              <span className="titleBold">Select a speaker for Conversation :</span>
              <select ref={talkSpeakerIdRef}>
                {speackerDevices.map((device) => {
                  return (
                    <option key={device.deviceId} value={device.deviceId}>
                      {device.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <Button onClick={handlerSetSpeaker}>
              <SaveOutlined />
              Save
            </Button>
          </div>
        </div>
        <Divider style={{ borderColor: "#7cb305" }}>Test Speakers</Divider>
        <div>
          <h3>Test RingTone Speaker :</h3>
          <div className="rowFlex gap10">
            <Button onClick={playRingTone}>
              <PlayCircleOutlined />
              Play Ringtone
            </Button>
            <Button onClick={stopRingTone}>
              <PauseCircleOutlined />
              Stop Ringtone
            </Button>
          </div>
        </div>
        <div>
          <h3>Test Conversation Speaker :</h3>
          <div className="rowFlex gap10">
            <Button onClick={playRemoteAudioTest}>
              <PlayCircleOutlined />
              Play Conversation
            </Button>
            <Button onClick={stopRemoteAudio}>
              <PauseCircleOutlined />
              Stop Conversation
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AudioDevices;
