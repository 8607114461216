import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button } from "antd";
import axios from "axios";
// import {checkLoginStatusInLocalStorage} from "./../../mylibs/list1";
// import {axiosi} from './../../../src/mylibs/list1'
// import useAxios from './../../CustomHooks/useAxios';
import useLogin from "./../../CustomHooks/useLogin";

import loginSlice from "./../../../src/redux/loginSlice";

export default function Login() {
  // const {axiosi}=useAxios();
  const { saveLoginStatus, removeLoginStatus } = useLogin();

  const dispatch = useDispatch();

  const onFinish = async (values) => {
    console.log("[Login]:done onFinish");
    const userlogin = { username: values.username, password: values.password };
    const kq = await axios.post(
      `${process.env.REACT_APP_DOMAIN}/users/login`,
      userlogin,
      { withCredentials: true }
    );
    console.log("[Login]:done /users/login");
    if (kq.data.code === 200) {
      saveLoginStatus("1");
      console.log("[Login]:kq.data=", kq.data);
      dispatch(
        loginSlice.actions.setWebrtc({ webrtc: kq.data.agents[0].webrtc })
      );
      dispatch(loginSlice.actions.setUser({ user: kq.data }));
    } else {
      console.log("[Login]:khac 200");
      removeLoginStatus();
    }

    console.log("done handlelogin");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const i = Math.floor(Math.random() * 10) + 1;
  return (
    <div
      style={{
        backgroundImage: `url("./img/${i}.jpg")`,
        with: "100%",
        height: "100vh",
        magrin: "0vh",
        position: "fixed",
        top: "0px",
        left: "0px",
        right: "0px",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      {/* // <div style={{"backgroundImage": `url("./img/Login.png")`,with:"100%",height:"100vh",magrin:"0vh",
        // position:"fixed",top:"0px",left:"0px",right:"0px",
        // "backgroundPosition": "center",
        // "backgroundSize": "cover"}}> */}
      <div
        style={{
          padding: "40px",
          width: "80vw",
          margin: "auto",
          background: "#72b4c482 none repeat scroll 0% 0%",
          color: "white",
          marginTop: "15%",
        }}
      >
        <Form
          // name="basic"
          name="normal_login"
          className="login-form"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            // label="Username"
            name="username"
            label={<label style={{ color: "white" }}>Username</label>}
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            // label="Password"
            name="password"
            label={<label style={{ color: "white" }}>Password</label>}
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          {/* <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Checkbox>Remember me</Checkbox>
      </Form.Item> */}
          <Form.Item
            // label=" "
            wrapperCol={{
              offset: 6,
              span: 18,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
